/* Have media qureies be 1312, 992, 688, and 320 */

/* -------------------- GLOBAL ELEMENTS BEGIN -------------------- */
@font-face {
  font-family: "Roboto Flex";
  src: url("RobotoFlex-VF.woff2") format("woff2 supports variations"),
    url("RobotoFlex-VF.woff2") format("woff2-variations");
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

:root {
  /* Applies to font color where the eye should be drawn to */
  --focused_font_color: #f0f0f0;
  /* Applies to primary text, such as h1 and h2 tags */
  --primary_font_color: #dadada;
  /* Applies to any tag that would need to show contrast to the primary font */
  --secondary_font_color: #ababab;
  /* Applies to the very root of the website, the furthermost back visible area */
  --btn_color: #f0f0f0;
  /* Applies to buttons with green background */
  --background_color: #111111;
  /* Applies to each main box on each page, the second layer the use sees*/
  --primary_color: #161616;
  /* Applies to the nav, footer, and contact inputs. Provides contrast to primary color */
  --secondary_color: #222222;
  /* Applies to buttons, focused inputs, and nav focused route links */
  --accent_color: #295928;
  /* Applies to the background of the modals in the about page, the furthermost back visible area */
  --about_card_bg: #222222;
  /* Applies to each main section of the modal */
  --about_modal_bg: #1d1d1d;
  /* Applies to the background that contains the theme buttons */
  --theme_cont_bg: #1d1d1d;
  /* Applies to the divider between routes in the nav bar */
  --nav_link_div_color: #f0f0f0;
  /* Applies to any text link */
  --text_link_color: #449a42;
  /* Applies to the inputs when there is incorrect value */
  --warning: #d43a3a;
  /* Applies to nav and footer tags */
  --tags: #474545;
  /* --tags: red; */
  --notes: #222222;

  /* Light theme */
  /* --primary_color: #b8c1c9;
  --background_color: #ebe9e9;
  --secondary_color: #9aa5aa; */
}

img {
  max-width: 100%;
}

.construction-note {
  color: var(--warning);
  font-weight: 900;
}

.App {
  text-align: center;
  background-color: var(--background_color);
  color: red;
  display: grid;
  padding: 0 40px;
  min-height: -webkit-fill-available;
  font-family: "Roboto Flex";
  /* defining how many columns I want */
  grid-template-columns: repeat(1, 1fr [col-start]);
  /* defining how many rows I want */
  grid-template-rows: 80px auto 505px 150px;
  gap: 30px;
  grid-template-areas:
    "nav"
    "body"
    "contact"
    "footer";
}

/* -------------------- GLOBAL ELEMENTS END -------------------- */

/* -------------------- TAG DECORATIONS BEGIN -------------------- */

.tag {
  font-family: "Bad Script", cursive;
  position: absolute;
  color: var(--tags);
  left: 70px;
  font-size: 14px;
}

.nav-opening-tag {
  font-family: "Bad Script", cursive;
  position: absolute;
  color: #474545;
  left: 10px;
  font-size: 14px;
  margin-top: 0;
}

.nav-closing-tag {
  font-family: "Bad Script", cursive;
  position: absolute;
  color: #474545;
  left: 10px;
  font-size: 14px;
  margin-top: 55px;
}

.contact-opening-tag {
  margin-top: 10px;
}

.contact-closing-tag {
  margin-top: 330px;
}

.footer-opening-tag {
  font-family: "Bad Script", cursive;
  position: absolute;
  color: var(--tags);
  left: 10px;
  font-size: 14px;
  margin-top: -20px;
}

.footer-closing-tag {
  font-family: "Bad Script", cursive;
  position: absolute;
  color: var(--tags);
  left: 10px;
  font-size: 14px;
  margin-top: 4px;
}

.footer-break-tag {
  padding: 13px 0;
  color: var(--tags);
  font-family: "Bad Script", cursive;
}

.home-opening-tag {
  margin-top: -150px;
}

.home-closing-tag {
  margin-top: 380px;
}

.home-button-tag {
  color: var(--tags);
  font-family: "Bad Script", cursive;
  line-height: 106px;
  margin: 0;
  font-size: 30px;
}

.project-opening-tag {
  margin-top: -50px;
}

.project-closing-tag {
  margin-top: 30px;
}

.about-opening-tag {
  margin-top: -33px;
}

.about-closing-tag {
  margin-top: 12px;
}

/* -------------------- TAG DECORATIONS END -------------------- */

/* -------------------- NAV BAR BEGIN -------------------- */

.nav-container {
  justify-content: space-between;
  grid-area: nav;
  background-color: #222222;
  display: inline-flex;
  margin: 0 -40px;
  padding: 0 40px;
  position: sticky;
  top: 0;
  opacity: 0.98;
  z-index: 999;
}

.nav-routes {
  display: inline-flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.nav-routes-divWrapper {
  display: flex;
}

.nav-routes-buttonWrapper {
  display: inline-flex;
  width: 82px;
  justify-content: center;
  outline: none;
}

.nav-routes-route {
  background-color: inherit;
  border: none;
  color: #f0f0f0;
  font-size: 16px;
  cursor: pointer;
  margin: 30px 0 30px 0;
}

.nav-routes-route:hover {
  text-decoration: underline;
}

.nav-routes-route:focus {
  outline: none;
}

.nav-routes-focus {
  font-weight: 600;
  outline: none;
  background-color: inherit;
  border: none;
  color: #f0f0f0;
  font-size: 16px;
  cursor: pointer;
  margin: 30px 0 30px 0;
}

.nav-routes-focus:hover {
  text-decoration: underline;
}

/* code taken from here: https://jaspreetchahal.org/pure-css-square-brackets/ */
.squarebrackets {
  position: relative;
  z-index: 999;
}
.squarebrackets:before {
  content: " ";
  position: absolute;
  border-left: 2px solid #3e833c;
  border-top: 2px solid #3e833c;
  border-bottom: 2px solid #3e833c;
  padding-top: 20px;
  padding-left: 4px;
  top: -1px;
  left: -15px;
  z-index: 999;
}
.squarebrackets:after {
  content: " ";
  position: absolute;
  border-right: 2px solid #3e833c;
  border-top: 2px solid #3e833c;
  border-bottom: 2px solid #3e833c;
  padding-top: 20px;
  padding-left: 4px;
  top: -1px;
  right: -15px;
  z-index: 999;
}

.nav-routes-divider {
  background-color: #f0f0f0;
  width: 1px;
  height: 20px;
  margin: 30px 50px;
}

.nav-logo {
  line-height: 80px;
  outline: none;
  cursor: pointer;
}

.nav-theme-wrapper {
  margin: 25px 0;
}

.nav-theme {
  display: inline-flex;
  width: 61px;
  height: 30px;
  border-radius: 5px;
  background-color: #1d1d1d;
  outline: none;
}

.nav-theme-divider {
  width: 1px;
  height: 20px;
  margin: 5px 0;
  background-color: #272727;
}

.nav-theme-svg {
  margin: 0 7px;
  outline: none;
}

/* Show tooltips https://www.w3schools.com/css/css_tooltip.asp */
.nav-theme-img {
  line-height: 30px;
  cursor: pointer;
}

.nav-theme-img .nav-theme-img-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 0 5px;
  top: 60px;
  left: 91%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.nav-theme-img:hover .nav-theme-img-text {
  visibility: visible;
  opacity: 1;
}

.nav-theme-note {
  color: var(--notes);
  font-weight: 900;
}

/* -------------------- NAV BAR END -------------------- */

/* -------------------- HOME BEGIN -------------------- */

.home-container {
  grid-area: body;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas:
    "intro profile"
    "viewProjects viewProjects";
  padding-top: 160px;
  text-align: left;
  border-radius: 4px;
  background-color: var(--primary_color);
}

.home-intro {
  grid-area: intro;
  padding-top: 35px;
  margin-left: 120px;
  width: 596px;
}

.home-intro-h2 {
  color: var(--primary_font_color);
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 1.07px;
}

.home-intro-p {
  color: var(--secondary_font_color);
  line-height: 30px;
  font-size: 24px;
  letter-spacing: 0.51px;
  max-width: 550px;
  margin-top: 40px;
}

.home-profile {
  grid-area: profile;
}

.home-profile-img {
  max-width: 339px;
  border-radius: 50%;
  float: right;
  margin-right: 120px;
}

.home-viewProjects {
  grid-area: viewProjects;
  justify-content: space-evenly;
  display: flex;
  color: var(--primary_font_color);
  padding-bottom: 120px;
}

.home-viewProjects > div {
  margin: 150px 0 40px 0;
  font-size: 20px;
}

.home-viewProjects-div-btn {
  text-align: center;
}

.home-viewProjects-button {
  height: 60px;
  width: 265px;
  border-radius: 5px;
  background-color: var(--accent_color);
  border: none;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.51px;
  line-height: 29px;
  color: var(--btn_color);
}

/* -------------------- HOME END -------------------- */

/* -------------------- PROJECT BEGIN -------------------- */

.project-container {
  grid-area: body;
  border-radius: 4px;
  align-items: center;
  padding: 60px 40px;
  background-color: var(--primary_color);
  color: var(--secondary_font_color);
}

.project-intro-h2 {
  color: var(--primary_font_color);
}

.project-primary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  text-align: left;
  gap: 40px;
}

.project-primary-player-div {
  height: auto;
  max-width: 620px;
  border-radius: 4px;
  background-color: var(--secondary_color);
  padding-top: 20px;
  margin-bottom: 50px;
}

.project-primary-player-h3 {
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  margin: 26px 0 4px 0;
  text-align: center;
  color: var(--primary_font_color);
}

.project-primary-player-h4 {
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  margin: 0;
  text-align: center;
  color: var(--primary_font_color);
}

.project-primary-player-descrip-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: var(--secondary_font_color);
  margin: 0 20px 12px 20px;
}

.project-primary-player-descrip-body {
  font-size: 20px;
  line-height: 24px;
  font-weight: 100;
  color: var(--secondary_font_color);
  margin: 0 20px;
}

.project-primary-player-descrip-list {
  font-size: 20px;
  line-height: 24px;
  font-weight: 100;
  color: var(--secondary_font_color);
  margin-bottom: 20px;
}

.project-primary-player-linkBtn {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 26px;
  padding: 0 20px;
  width: 123px;
  height: 40px;
  font-size: 18px;
  line-height: 21px;
  border-radius: 5px;
  background-color: var(--theme_cont_bg);
  color: var(--text_link_color);
  border: none;
}

.project-primary-player-linkBtn:focus {
  outline: none;
}

.project-primary-player-linkBtn:hover {
  color: var(--secondary_font_color);
}

#project-primary-player-LEABtn {
  margin-top: 92px;
}

.project-primary-lakeElmoAero {
  background-image: url("./images/Miscellaneous/LakeElmo.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  border-radius: 10px;
  margin: 24px 20px;
}

.project-primary-financeIt {
  background-image: url("./images/Miscellaneous/financeIt.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  border-radius: 10px;
  margin: 24px 20px;
}

.project-primary-modalBtn {
  background-color: transparent;
  margin: 22% 41% 22% 44%;
  border: none;
}

.project-primary-modalBtn:focus {
  outline: none;
}
.project-primary-modalBtn:hover {
  transition: 0.3s ease;
  transform: scale(1.3, 1.3);
  opacity: 1;
}

.project-primary-modal-title {
  padding-left: 10px;
}

.project-modal-body {
  background-color: var(--about_modal_bg);
  padding: 6px 14px;
  margin: -16px;
  margin-top: -18px;
}

.project-secondary {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.project-item {
  min-width: 200px;
  width: 260px;
  height: 375px;
  background-color: var(--secondary_color);
  border-radius: 4px;
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr [col-start]);
  grid-template-rows: auto auto;
  grid-template-areas:
    "title"
    "description";
}

.project-item-title {
  grid-area: title;
  margin-top: 26px;
}

.project-item-title > h4 {
  font-size: 26px;
  line-height: 30px;
  color: var(--primary_font_color);
  margin: 0;
}

.project-item-title > p {
  font-size: 18px;
  line-height: 21px;
  color: var(--primary_font_color);
  margin-top: 4px;
  margin-bottom: 24px;
}

.project-item-image {
  height: 250px;
  margin: 0;
}

.project-item-image:hover .project-description {
  opacity: 1;
  visibility: visible;
}

.project-imgElement:focus {
  outline: none;
}

.project-imgElement {
  border-radius: 4px;
}

.project-description {
  position: absolute;
  display: table-cell;
  vertical-align: middle;
  height: 230px;
  width: 230px;
  border-radius: 4px;
  padding: 5px;
  margin: -230px 0 0 15px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
}

.project-description-bg {
  height: 230px;
  width: 230px;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  z-index: 998;
  border-radius: 4px;
}

.project-description-divElement {
  height: 230px;
  width: 230px;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  background-color: #222222b2;
  z-index: 999;
  padding: 20px 10px 0 10px;
  color: var(--secondary_font_color);
  border-radius: 4px;
  text-align: left;
}

.project-description-link {
  cursor: pointer;
  padding: 2px;
  margin-left: 10px;
  z-index: 999;
  opacity: 0.5;
  background-color: var(--theme_cont_bg);
  border-radius: 5px;
}

.project-description-link:hover {
  transition: 0.3s ease;
  transform: scale(1.3, 1.3);
  opacity: 1;
}

.project-description-link:focus {
  outline: none;
}

.project-description-underlineImg:hover {
  height: 1px;
  width: 20px;
  background-color: black;
}

.project-item-description {
  grid-area: description;
  width: 200px;
  justify-self: center;
  z-index: 999;
}

.project-item-accordian {
  border-radius: 10px;
  margin: 0;
}

.project-item-accordianSummary {
  margin: 0;
}

/* -------------------- PROJECT END -------------------- */

/* -------------------- TOOLKIT BEGIN -------------------- */

.toolkit-container > div > div > p {
  list-style-type: none;
  margin: 5px;
}

.toolkit-container > div {
  display: inline-flex;
  flex-wrap: wrap;
}

.toolkit-container {
  grid-area: body;
  border-radius: 4px;
  align-items: center;
  padding: 40px 50px 40px 50px;
  background-color: var(--primary_color);
  color: var(--primary_font_color);
}

.tki-div {
  background-color: var(--secondary_color);
  color: var(--secondary_font_color);
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  height: fit-content;
}

/* -------------------- TOOLKIT END -------------------- */

/* -------------------- ABOUT BEGIN -------------------- */

.about-container {
  grid-area: body;
  border-radius: 4px;
  align-items: center;
  padding: 40px 50px 40px 50px;
  background-color: var(--primary_color);
  color: var(--secondary_font_color);
}

.about-list {
  list-style-type: none;
  padding: 0;
  line-height: 30px;
  font-size: 24px;
}

.about-modal-title {
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.card {
  background-color: var(--about_card_bg);
  color: var(--secondary_font_color);
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
  /* overflow: hidden; */
}

.aboutitem-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.aboutitem-button {
  display: flex;
  background-color: var(--primary_color);
  border-radius: 4px;
  color: var(--focused_font_color);
  box-sizing: none;
  border: none;
  margin: 0 10px 20px 10px;
  padding: 0;
  width: 140px;
  height: 140px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
}

.aboutitem-button:focus {
  outline: none;
}

.aboutitem-button-img {
  object-fit: cover;
  height: 100%;
  border-radius: 4px;
}

#modalHeader {
  height: 100px;
  padding-top: 30px;
  background-color: var(--about_modal_bg);
  color: var(--primary_font_color);
}

#aboutitem-modal {
  background-color: var(--about_modal_bg);
  border-radius: 0 0 4px 4px;
}

.about-intro-h2 {
  color: var(--primary_font_color);
  font-size: 34px;
  line-height: 60px;
}

.about-personality-h3 {
  color: var(--primary_font_color);
  font-size: 34px;
  line-height: 60px;
}

/* -------------------- ABOUT BEGIN -------------------- */

/* -------------------- CONTACT BEGIN -------------------- */

.contact-container {
  grid-area: contact;
  border-radius: 4px;
  align-items: center;
  padding-bottom: 40px;
  background-color: var(--primary_color);
}

.contact-intro {
  display: block;
}

.contact-intro-h3 {
  color: var(--primary_font_color);
  font-size: 24px;
  font-weight: 500;
  padding: 40px 0 10px 0;
  margin: 0;
  line-height: 28px;
}

.contact-intro2 {
  display: inline-flex;
}

.contact-intro2-h3 {
  color: var(--secondary_font_color);
  font-size: 18px;
  font-weight: 100;
  padding-bottom: 40px;
  margin: 0;
  line-height: 21px;
}

.contact-linkedin-link {
  margin: 0 2px;
  color: var(--text_link_color);
  text-decoration: underline;
}

.contact-linkedin-link:hover {
  color: var(--secondary_font_color);
}

.contact-form-container {
  max-width: 820px;
  width: 100%;
  display: grid;
  gap: 30px 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 68px 168px auto;
  grid-template-areas:
    "name  email"
    "message message"
    "button .";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  text-align: start;
  color: var(--focused_font_color);
}

.contact-form-container-p {
  margin-bottom: 4px;
  font-size: 14px;
  font-size: 12px;
  line-height: 14px;
}

.contact-name {
  grid-area: name;
}

.contact-name-input {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary_color);
  color: var(--primary_font_color);
}

.contact-name-input:focus {
  border: 1px solid var(--accent_color);
  outline: none;
}

.contact-email {
  grid-area: email;
}

.contact-email-input {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary_color);
  color: var(--primary_font_color);
}

.contact-email-input:focus {
  border: 1px solid var(--accent_color);
  outline: none;
}

.contact-message {
  grid-area: message;
}

.contact-message-input {
  max-height: 150px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary_color);
  color: var(--primary_font_color);
}

.contact-message-input:focus {
  border: 1px solid var(--accent_color);
  outline: none;
}

.contact-button {
  grid-area: button;
  text-align: start;
}

.contact-button-send {
  height: 40px;
  width: 97px;
  border-radius: 5px;
  margin-top: -10px;
  background-color: var(--accent_color);
  border: none;
  cursor: pointer;
  color: var(--btn_color);
}

/* Class for fields that are not valid */
.is-invalid {
  border: 1px solid var(--warning);
}

.invalid-feedback {
  color: var(--warning);
}

.contact-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
}

.contact-modal-header {
  display: block;
  text-align: center;
  border-radius: 4px;
}

/* -------------------- CONTACT END -------------------- */

/* -------------------- FOOTER BEGIN -------------------- */

.footer-container {
  grid-area: footer;
  background-color: var(--secondary_color);
  color: var(--focused_font_color);
  font-size: 12px;
  margin: 0 -40px;
  padding: 30px 40px;
}

.footer-container > p {
  margin: 0;
  line-height: 14px;
  font-weight: 200;
}

.footer-Ptag {
  padding-top: 4px;
}

.footer-UX-link {
  color: var(--focused_font_color);
  text-decoration: underline;
  margin: 0 2px;
  cursor: pointer;
}

.footer-UX-link:hover {
  color: var(--secondary_font_color);
}

.footer-social-links {
  display: flex;
  float: right;
  margin-top: -59px;
  gap: 22px;
}

.footer-social-linkedin {
  background-image: url("./images/SocialLinks/LinkedInGray.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.footer-social-linkedin:hover {
  background-image: url("./images/SocialLinks/LinkedInGreen.svg");
}

.footer-social-github {
  background-image: url("./images/SocialLinks/GitHubGray.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.footer-social-github:hover {
  background-image: url("./images/SocialLinks/GitHubGreen.svg");
}

/* -------------------- FOOTER END -------------------- */

/* ---------------------------------------- BREAKPOINT 1310 ---------------------------------------- */

/* max-width means "if screen is less than or equal to specified size then do {...}" */
/* min-width means "if screen is greater than or equal to specified size then do {...}" */

@media screen and (max-width: 1310px) {
  /* -------------------- HOME BEGIN 1310 -------------------- */

  .home-intro {
    margin-left: 40px;
  }

  .home-profile-img {
    margin-right: 40px;
    width: 70%;
  }

  .home-intro-h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .home-intro-p {
    font-size: 20px;
    line-height: 24px;
  }

  /* -------------------- HOME END 1310 -------------------- */

  /* -------------------- PROJECT BEGIN 1310 -------------------- */
  #project-primary-player-LEABtn {
    margin-top: 20px;
  }

  .project-primary-player-div {
    padding: 20px 10px 0 10px;
  }
  /* -------------------- PROJECT END 1310 -------------------- */
}

/* ---------------------------------------- BREAKPOINT 992 ---------------------------------------- */

@media screen and (max-width: 992px) {
  .App {
    padding: 0 20px;
    grid-template-rows: 80px auto 495px 192px;
  }

  /* -------------------- TAG DECORATIONS BEGIN 992 -------------------- */

  .tag {
    left: 30px;
  }

  .home-closing-tag {
    margin-top: 440px;
  }

  .home-button-tag {
    font-size: 24px;
  }

  .contact-closing-tag {
    margin-top: 5px;
  }

  .footer-closing-tag {
    margin-top: -18px;
  }

  /* -------------------- TAG DECORATIONS END 992 -------------------- */

  /* -------------------- NAV BEGIN 992 -------------------- */

  .nav-container {
    margin: 0 -20px;
    padding: 0 20px;
  }

  .nav-routes-divider {
    margin: 30px 40px;
  }

  /* -------------------- NAV END 992 -------------------- */

  /* -------------------- HOME BEGIN 992 -------------------- */

  .home-intro {
    width: 455px;
    margin-left: 20px;
  }

  .home-intro-h2 {
    font-size: 34px;
    line-height: 44px;
  }

  .home-intro-p {
    font-size: 18px;
    line-height: 24px;
    /* max-width: 455px; */
  }

  .home-profile-img {
    min-width: 130px;
    margin-right: 20px;
  }

  .home-viewProjects > div {
    font-size: 18px;
  }

  .home-viewProjects-button {
    width: 225px;
    font-size: 18px;
  }

  /* -------------------- HOME END 992 -------------------- */

  /* -------------------- ABOUT BEGIN 992 -------------------- */

  .about-intro-h2 {
    font-size: 34px;
    line-height: 44px;
  }

  .about-intro-h3 {
    font-size: 34px;
    line-height: 44px;
  }

  .about-list {
    font-size: 20px;
    line-height: 24px;
  }

  /* -------------------- ABOUT END 992 -------------------- */

  /* -------------------- PROJECT BEGIN 992 -------------------- */

  .project-primary-modalBtn {
    margin: 20% 37% 18% 41%;
  }

  /* -------------------- PROJECT END 992 -------------------- */

  /* -------------------- CONTACT BEGIN 992 -------------------- */

  .contact-container {
    padding: 0 40px 40px 40px;
  }

  .contact-form-container {
    position: inherit;
    left: 0;
    transform: translate(0);
    max-width: 100%;
  }

  /* -------------------- CONTACT END 992 -------------------- */

  /* -------------------- FOOTER BEGIN 992 -------------------- */

  .footer-container {
    margin: 0 -20px;
    padding: 30px 20px 20px 20px;
  }

  .footer-social-links {
    float: none;
    margin: 20px 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  /* -------------------- FOOTER END 992 -------------------- */
}

/* ---------------------------------------- BREAKPOINT 688 ---------------------------------------- */

@media screen and (max-width: 688px) {
  .App {
    padding: 0 10px;
    grid-template-rows: 64px auto 595px 235px;
  }

  /* -------------------- TAGS START 688 -------------------- */
  .nav-closing-tag {
    margin-top: 47px;
  }

  .footer-closing-tag {
    margin-top: 90px;
  }

  .footer-break-tag {
    padding: 12px 0;
  }

  .home-opening-tag {
    margin-top: -20px;
  }

  .home-closing-tag {
    margin-top: 395px;
  }

  /* -------------------- TAGS END 688 -------------------- */

  /* -------------------- NAV BEGIN 688 -------------------- */

  .nav-container {
    margin: 0 -10px;
    padding: 0 10px;
  }

  .nav-routes-divider {
    margin: 30px 40px;
  }

  .nav-hamburger {
    line-height: 64px;
    margin-right: -16px;
  }

  .nav-hamburger-btn:focus {
    outline: none;
  }

  .nav-theme {
    width: 89px;
    height: 44px;
  }

  .nav-theme-wrapper {
    margin: 9.5px 0 0 0;
    padding-bottom: 25px;
  }

  .nav-theme-img {
    line-height: 44px;
  }

  .nav-theme-svg {
    margin: 0 12px;
    width: 20px;
  }

  .nav-theme-divider {
    margin: 12px 0;
  }

  .nav-theme-img .nav-theme-img-text {
    left: 68%;
  }

  .nav-logo {
    line-height: 64px;
  }

  .nav-mobileLogo {
    display: flex;
    z-index: 999;
  }

  .nav-mobileMenu-closed {
    display: flex;
    z-index: 999;
    background-image: url("./images/MenuIcons/MenuIconActive.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 44px;
    height: 44px;
  }

  .nav-mobileMenu-closed:hover {
    background-image: url("./images/MenuIcons/MenuIconHover.svg");
  }

  .nav-mobileMenu-open {
    display: flex;
    z-index: 999;
    background-image: url("./images/MenuIcons/CloseIconActive.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 44px;
    height: 44px;
  }

  .nav-mobileMenu-open:hover {
    background-image: url("./images/MenuIcons/CloseIconHover.svg");
  }

  .nav-routes-mobile {
    background-color: var(--secondary_color);
    display: block;
    justify-content: center;
  }

  .nav-routes-mobile-divider {
    height: 1px;
    width: 100vw;
    background-color: var(--tags);
    margin: 0 -10px 4.5px -10px;
  }

  .nav-routes-divWrapper {
    display: block;
  }

  .nav-routes-route {
    margin: 12.5px 0 12.5px 0;
  }

  .nav-routes-focus {
    margin: 12.5px 0 12.5px 0;
  }

  /* -------------------- NAV END 688 -------------------- */

  /* -------------------- HOME BEGIN 688 -------------------- */

  .home-container {
    padding-top: 30px;
    grid-template-columns: auto;
    grid-template-areas:
      "profile"
      "intro"
      "viewProjects";
  }

  .home-profile-img {
    width: 100px;
    min-width: 100px;
    margin-right: 10px;
  }

  .home-intro {
    padding-top: 0;
    margin-top: -22px;
    margin-left: 10px;
    width: auto;
  }

  .home-intro-h2 {
    font-size: 23px;
    line-height: 29px;
    font-weight: 493;
  }

  .home-intro-p {
    font-size: 16px;
    line-height: 20px;
  }

  .home-viewProjects {
    padding-top: 120px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
  }

  .home-viewProjects > div {
    margin: 0;
  }

  .home-viewProjects > p {
    margin: 90px 40px 40px 40px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  /* -------------------- HOME END 688 -------------------- */

  /* -------------------- PROJECTS BEGIN 688 -------------------- */

  .project-container {
    padding: 40px 10px;
  }

  .project-primary-modalBtn {
    margin: 22% 37% 18% 41%;
  }

  /* -------------------- PROJECTS END 688 -------------------- */

  /* -------------------- ABOUT BEGIN 688 -------------------- */

  .about-intro-h2 {
    font-size: 23px;
    line-height: 29px;
    font-weight: 493;
  }

  .about-personality-h3 {
    font-size: 23px;
    line-height: 29px;
    font-weight: 493;
  }

  .about-list {
    font-size: 16px;
    line-height: 20px;
  }

  /* -------------------- ABOUT END 688 -------------------- */

  /* -------------------- CONTACT BEGIN 688 -------------------- */

  .contact-form-container {
    /* max-width: 100%; */
    position: inherit;
    left: 0;
    transform: translate(0);
    gap: 20px 10px;
    grid-template-columns: auto;
    grid-template-rows: 65px 65px 195px auto auto;
    grid-template-areas:
      "name"
      "email"
      "message"
      "button";
  }

  .contact-container {
    padding: 0 10px 30px 10px;
  }

  .contact-intro-h3 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    padding: 30px 60px 10px 60px;
  }

  .contact-intro2-h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 100;
    padding-bottom: 28px;
    margin: 0 10px;
  }

  .contact-form-container-p {
    font-size: 10px;
    line-height: 11px;
  }

  .contact-button-send {
    margin-top: 0;
  }

  /* -------------------- CONTACT END 688 -------------------- */

  /* -------------------- FOOTER BEGIN 688 -------------------- */

  .footer-container {
    margin: 0 -10px;
    padding: 30px 20px 20px 20px;
  }

  .footer-container > p {
    font-size: 11px;
    line-height: 13px;
  }

  .footer-Ptag {
    padding: 12px 60px 0 60px;
  }

  .footer-social-links {
    margin: 25px 0 30px 0;
  }

  /* -------------------- FOOTER END 688 -------------------- */
}
